<template>
    <tr>
        <td>
            <strong>{{ log.id }}</strong>
        </td>
        <td class="nowrap-column">
            {{ log.employee.name }}
        </td>
        <td style="min-width: 250px;">
            {{ log.url }}
            <strong class="font-small">
                {{ log.type }}
            </strong>
        </td>
        <td>
            {{ log.ip }}
        </td>
        <td class="nowrap-column">
            {{ log.created_date }}
        </td>
    </tr>
</template>

<script>
    export default {
        name: 'log-item',

        components: {

        },

        props: {
            log: {
                type: Object,
                default() {
                    return this.$props.$_PaginationObject;
                }
            }
        },

        methods: {

        }
    }
</script>

<style scoped>

</style>